<template>

    <v-container fluid>

        <!-- Section Path File/Modul -->
        <v-breadcrumbs 
            :items="pathModul"
            divider="-"
            normal
            class="pathModul"
        ></v-breadcrumbs>

        <!-- Section Filtering Input/Select/Button -->
        <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'" style="padding-top: 0px !important;padding-bottom: 0px !important;"> 

            <v-row align="center">
 
                <!-- Column Select Station -->
                <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'" class="col-input-filter py-1">
                    <v-select
                    v-on:change="selectValueStation"
                    :items="itemStation"
                    label="Station"
                    solo dense
                    prepend-inner-icon="mdi-store-marker"
                    v-model="modelStationSelected" 
                    >
                    </v-select>
                </v-col>

                <!-- Column Select Calendar From-->
                <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'" class="col-input-filter  py-1">
                    <v-menu
                    v-model="modalCalendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateFrom"
                        prefix="From :"
                        prepend-inner-icon="mdi-calendar-start"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        solo dense
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateFrom"
                        @input="modalCalendar = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- Column Select Calendar To-->
                <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'" class="col-input-filter py-1">
                    <v-menu
                    v-model="modalCalendar2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateTo"
                        prefix="To :"
                        prepend-inner-icon="mdi-calendar-end"
                        readonly solo dense
                        v-bind="attrs"
                        v-on="on"
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateTo"
                        @input="modalCalendar2 = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- <v-col>
                    <v-text-field @input="test">

                    </v-text-field>
                </v-col> -->

                <!-- Column Generate Button -->
                <v-col cols="12" sm="6" md="4" class="col-btn-generateReport">
                    <v-btn
                    id="generateReport"
                    class="white--text btn-generate button mb-5"
                    solo dense normal
                    :disabled="modelStationSelected == ''"
                    @click="generateTable"
                    >
                      GENERATE REPORT
                    </v-btn>
                </v-col>

            </v-row>

        </v-container>

        <!-- Section Result Generated Report (TABLE) -->
        <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'container-result-generated form-datatable px-6' : 'container-result-generated form-datatable px-1'" style="padding-top: 0px !important;padding-bottom: 0px !important;">

            <!-- Main Report Title/Tab -->

            <v-tabs
            v-model="tabs"
            show-arrows="mobile"
            >
                <v-tab v-for="item in tabsTypeValidation" :key="item.tab">
                    {{ item.tab }}
                </v-tab>
            </v-tabs>

            <!-- Mini Title (Table Title) -->
    
            <v-tabs-items v-model="tabs" touchless>

                <!-- Content Tab Validation Report -->
                <v-tab-item :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">

                    <v-subheader class="subTitle black--text font-weight-bold" style="text-transform:uppercase">
                        DATA VALIDATION RECORD FOR STATION {{stationID}} - {{convertUcase(this.locationStation)}} FROM {{ convertDateFormat(dateFrom) }} TO {{ convertDateFormat(dateTo) }}
                    </v-subheader>

                    <div id="pageDepanValidationReport" class="mt-8">

                        <!-- Button Export Table -->
                        <div class="section-btn-export" v-if="this.$store.getters.user.idUserAccess !== 3">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="excel"
                                elevation="2"
                                v-bind="attrs"
                                v-on="on"
                                @click="exportExcel()"
                                class="btn-export-table btn-excel"
                                >
                                    <v-icon
                                    color="white"
                                    center
                                    dark
                                    >
                                        mdi-microsoft-excel
                                    </v-icon>
                                </v-btn>
                                </template>
                                <span>Export to Excel</span>
                            </v-tooltip>
                        </div>
                        
                        
                        <!-- Table Report-->
                        <v-data-table
                        :headers="thead_validation_report"
                        :items="tbody_validation_report"
                        :header-props="{ sortIcon: null }"
                        class="elevation-1 tableValidationReport headerDtSarawak headerDesktop"
                        :items-per-page="itemPerPageReport"
                        no-select-on-click
                        fixed-header
                        :loading="this.loadingTable"
                        :loading-text="this.loadingText"
                        :mobile-breakpoint="0"
                        >
                            <template v-slot:[`item.DATETIME`]="{ item }">
                                <!-- {{ item.DATETIME }} -->
                                <span v-html="item.DATETIME"></span>
                            </template>


                            <!-- Slot Parameter Start -->

                            <template v-slot:[`item.CONDUCTIVITY`]="{ item }">
                                <div v-if="item.CONDUCTIVITY_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CONDUCTIVITY }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CONDUCTIVITY_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CONDUCTIVITY',item.CONDUCTIVITY,$event,item.CONDUCTIVITY_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CONDUCTIVITY }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CONDUCTIVITY_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.WQI`]="{ item }">
                                <!-- <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'WQI',item.WQI,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.WQI }}
                                </v-btn> -->
                                <div v-if="item.WQI_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.WQI }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.WQI_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'WQI',item.WQI,$event,item.WQI_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.WQI }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.WQI_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SI_DO_SAT`]="{ item }">
                                <!-- <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SI_DO_SAT',item.SI_DO_SAT,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_DO_SAT }}
                                </v-btn> -->
                                <div v-if="item.SI_DO_SAT_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                        {{ item.SI_DO_SAT }}
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SI_DO_SAT',item.SI_DO_SAT,$event)" tile elevation="0" class="btn-click-td">
                                        {{ item.SI_DO_SAT }}
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.DO_CON`]="{ item }">
                                <div v-if="item.DO_CON_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.DO_CON }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DO_CON_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'DO_CON',item.DO_CON,$event,item.DO_CON_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.DO_CON }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DO_CON_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.DO_SAT`]="{ item }">
                                <div v-if="item.DO_SAT_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.DO_SAT }}
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DO_SAT_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'DO_SAT',item.DO_SAT,$event,item.DO_SAT_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.DO_SAT }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DO_SAT_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SI_BOD`]="{ item }">
                                <div v-if="item.SI_BOD_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                        {{ item.SI_BOD }} 
                                    </v-btn>
                                </div>
                                <div v-else>
                                <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SI_BOD',item.SI_BOD,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_BOD }} 
                                </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.BOD`]="{ item }">
                                <div v-if="item.BOD_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.BOD }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BOD_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'BOD',item.BOD,$event,item.BOD_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.BOD }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BOD_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SI_COD`]="{ item }">
                                <div v-if="item.SI_COD_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                        {{ item.SI_COD }} 
                                    </v-btn>
                                </div>
                                <div v-else>
                                <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SI_COD',item.SI_COD,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_COD }} 
                                </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.COD`]="{ item }">
                                <div v-if="item.COD_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.COD }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.COD_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'COD',item.COD,$event,item.COD_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.COD }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.COD_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SI_NH3N`]="{ item }">
                                <div v-if="item.SI_NH3N">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                        {{ item.SI_NH3N }} 
                                    </v-btn>
                                </div>
                                <div v-else>
                                <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SI_NH3N',item.SI_NH3N,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_NH3N }} 
                                </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.NH3N`]="{ item }">
                                <div v-if="item.NH3N_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.NH3N }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NH3N_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'NH3N',item.NH3N,$event,item.NH3N_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.NH3N }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NH3N_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SI_TSS`]="{ item }">
                                <div v-if="item.SI_TSS">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                        {{ item.SI_TSS }} 
                                    </v-btn>
                                </div>
                                <div v-else>
                                <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SI_TSS',item.SI_TSS,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_TSS }} 
                                </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.TSS`]="{ item }">
                                <div v-if="item.TSS_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.TSS }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TSS_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'TSS',item.TSS,$event,item.TSS_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.TSS }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TSS_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SI_pH`]="{ item }">
                                <div v-if="item.SI_TSS">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                        {{ item.SI_pH }} 
                                    </v-btn>
                                </div>
                                <div v-else>
                                <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SI_pH',item.SI_pH,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_pH }} 
                                </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.pH`]="{ item }">
                                <div v-if="item.pH_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.pH }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.pH_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'pH',item.pH,$event,item.pH_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.pH }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.pH_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item._4_4_DDT`]="{ item }">
                                <div v-if="item._4_4_DDT_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item._4_4_DDT }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item._4_4_DDT_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'_4_4_DDT',item._4_4_DDT,$event,item._4_4_DDT_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item._4_4_DDT }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item._4_4_DDT_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>


                            <template v-slot:[`item.AG`]="{ item }">
                                <div v-if="item.AG_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.AG }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.AG_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'AG',item.AG,$event,item.AG_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.AG }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.AG_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.AL`]="{ item }">
                                <div v-if="item.AL_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.AL }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.AL_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'AL',item.AL,$event,item.AL_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.AL }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.AL_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.ALDRIN_DIELDRIN`]="{ item }">
                                <div v-if="item.ALDRIN_DIELDRIN_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.ALDRIN_DIELDRIN }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.ALDRIN_DIELDRIN_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'ALDRIN_DIELDRIN',item.ALDRIN_DIELDRIN,$event,item.ALDRIN_DIELDRIN_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.ALDRIN_DIELDRIN }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.ALDRIN_DIELDRIN_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.AS`]="{ item }">
                                <div v-if="item.AS_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.AS }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.AS_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'AS',item.AS,$event,item.AS_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.AS }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.AS_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.B`]="{ item }">
                                <div v-if="item.B_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.B }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.B_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'B',item.B,$event,item.B_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.B }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.B_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.BA`]="{ item }">
                                <div v-if="item.BA_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.BA }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BA_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'BA',item.BA,$event,item.BA_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.BA }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BA_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.BHC`]="{ item }">
                                <div v-if="item.BHC_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.BHC }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BHC_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'BHC',item.BHC,$event,item.BHC_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.BHC }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BHC_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.BR`]="{ item }">
                                <div v-if="item.BR_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.BR }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BR_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'BR',item.BR,$event,item.BR_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.BR }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BR_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CA`]="{ item }">
                                <div v-if="item.CA_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CA }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CA_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CA',item.CA,$event,item.CA_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CA }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CA_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CCE`]="{ item }">
                                <div v-if="item.CCE_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CCE }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CCE_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CCE',item.CCE,$event,item.CCE_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CCE }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CCE_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CD`]="{ item }">
                                <div v-if="item.CD_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CD }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CD_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CD',item.CD,$event,item.CD_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CD }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CD_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CDOM`]="{ item }">
                                <div v-if="item.CDOM_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CDOM }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CDOM_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CDOM',item.CDOM,$event,item.CDOM_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CDOM }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CDOM_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CHLORDANE`]="{ item }">
                                <div v-if="item.CHLORDANE_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CHLORDANE }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CHLORDANE_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CHLORDANE',item.CHLORDANE,$event,item.CHLORDANE_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CHLORDANE }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CHLORDANE_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CHLOROPHYLL_A`]="{ item }">
                                <div v-if="item.CHLOROPHYLL_A_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CHLOROPHYLL_A }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CHLOROPHYLL_A_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CHLOROPHYLL_A',item.CHLOROPHYLL_A,$event,item.CHLOROPHYLL_A_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CHLOROPHYLL_A }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CHLOROPHYLL_A_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CL`]="{ item }">
                                <div v-if="item.CL_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CL }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CL_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CL',item.CL,$event,item.CL_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CL }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CL_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CL2`]="{ item }">
                                <div v-if="item.CL2_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CL2 }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CL2_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CL2',item.CL2,$event,item.CL2_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CL2 }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CL2_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CN`]="{ item }">
                                <div v-if="item.CN_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CN }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CN_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CN',item.CN,$event,item.CN_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CN }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CN_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CO2`]="{ item }">
                                <div v-if="item.CO2_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CO2 }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CO2_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CO2',item.CO2,$event,item.CO2_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CO2 }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CO2_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.COLOUR`]="{ item }">
                                <div v-if="item.COLOUR_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.COLOUR }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.COLOUR_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'COLOUR',item.COLOUR,$event,item.COLOUR_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.COLOUR }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.COLOUR_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CR`]="{ item }">
                                <div v-if="item.CR_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CR }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CR_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CR',item.CR,$event,item.CR_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CR }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CR_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CR_III`]="{ item }">
                                <div v-if="item.CR_III_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CR_III }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CR_III_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CR_III',item.CR_III,$event,item.CR_III_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CR_III }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CR_III_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CR_IV`]="{ item }">
                                <div v-if="item.CR_IV_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CR_IV }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CR_IV_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CR_IV',item.CR_IV,$event,item.CR_IV_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CR_IV }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CR_IV_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.CU`]="{ item }">
                                <div v-if="item.CU_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.CU }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CU_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'CU',item.CU,$event,item.CU_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.CU }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CU_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.DEPTH`]="{ item }">
                                <div v-if="item.DEPTH_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.DEPTH }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DEPTH_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'DEPTH',item.DEPTH,$event,item.DEPTH_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.DEPTH }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DEPTH_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.DOC`]="{ item }">
                                <div v-if="item.DOC_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.DOC }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DOC_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'DOC',item.DOC,$event,item.DOC_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.DOC }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DOC_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.E_COLI`]="{ item }">
                                <div v-if="item.E_COLI_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.E_COLI }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.E_COLI_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'E_COLI',item.E_COLI,$event,item.E_COLI_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.E_COLI }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.E_COLI_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.EC`]="{ item }">
                                <div v-if="item.EC_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.EC }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.EC_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'EC',item.EC,$event,item.EC_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.EC }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.EC_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.ENDOSULFAN`]="{ item }">
                                <div v-if="item.ENDOSULFAN_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.ENDOSULFAN }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.ENDOSULFAN_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'ENDOSULFAN',item.ENDOSULFAN,$event,item.ENDOSULFAN_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.ENDOSULFAN }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.ENDOSULFAN_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.F`]="{ item }">
                                <div v-if="item.F_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.F }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.F_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'F',item.F,$event,item.F_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.F }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.F_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.FC`]="{ item }">
                                <div v-if="item.FC_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.FC }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FC_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'FC',item.FC,$event,item.FC_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.FC }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FC_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.FDOM`]="{ item }">
                                <div v-if="item.FDOM_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.FDOM }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FDOM_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'FDOM',item.FDOM,$event,item.FDOM_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.FDOM }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FDOM_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.FE`]="{ item }">
                                <div v-if="item.FE_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.FE }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FE_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'FE',item.FE,$event,item.FE_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.FE }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FE_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.FLOWRATE`]="{ item }">
                                <div v-if="item.FLOWRATE_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.FLOWRATE }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FLOWRATE_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'FLOWRATE',item.FLOWRATE,$event,item.FLOWRATE_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.FLOWRATE }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FLOWRATE_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.GROSS_ALPHA`]="{ item }">
                                <div v-if="item.GROSS_ALPHA_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.GROSS_ALPHA }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.GROSS_ALPHA_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'GROSS_ALPHA',item.GROSS_ALPHA,$event,item.GROSS_ALPHA_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.GROSS_ALPHA }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.GROSS_ALPHA_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.GROSS_BETA`]="{ item }">
                                <div v-if="item.GROSS_BETA_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.GROSS_BETA }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.GROSS_BETA_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'GROSS_BETA',item.GROSS_BETA,$event,item.GROSS_BETA_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.GROSS_BETA }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.GROSS_BETA_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.HARDNESS`]="{ item }">
                                <div v-if="item.HARDNESS_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.HARDNESS }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HARDNESS_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'HARDNESS',item.HARDNESS,$event,item.HARDNESS_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.HARDNESS }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HARDNESS_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.HEDONAL_2_4_D`]="{ item }">
                                <div v-if="item.HEDONAL_2_4_D_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.HEDONAL_2_4_D }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HEDONAL_2_4_D_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'HEDONAL_2_4_D',item.HEDONAL_2_4_D,$event,item.HEDONAL_2_4_D_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.HEDONAL_2_4_D }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HEDONAL_2_4_D_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.HEPTACHLOR_EPOXIDE`]="{ item }">
                                <div v-if="item.HEPTACHLOR_EPOXIDE_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.HEPTACHLOR_EPOXIDE }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HEPTACHLOR_EPOXIDE_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'HEPTACHLOR_EPOXIDE',item.HEPTACHLOR_EPOXIDE,$event,item.HEPTACHLOR_EPOXIDE_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.HEPTACHLOR_EPOXIDE }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HEPTACHLOR_EPOXIDE_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.HG`]="{ item }">
                                <div v-if="item.HG_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.HG }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HG_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'HG',item.HG,$event,item.HG_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.HG }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HG_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.K`]="{ item }">
                                <div v-if="item.K_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.K }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.K_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'K',item.K,$event,item.K_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.K }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.K_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.LINDANE`]="{ item }">
                                <div v-if="item.LINDANE_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.LINDANE }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.LINDANE_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'LINDANE',item.LINDANE,$event,item.LINDANE_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.LINDANE }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.LINDANE_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.MBAS`]="{ item }">
                                <div v-if="item.MBAS_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.MBAS }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.MBAS_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'MBAS',item.MBAS,$event,item.MBAS_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.MBAS }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.MBAS_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.MG`]="{ item }">
                                <div v-if="item.MG_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.MG }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.MG_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'MG',item.MG,$event,item.MG_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.MG }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.MG_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.MN`]="{ item }">
                                <div v-if="item.MN_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.MN }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.MN_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'MN',item.MN,$event,item.MN_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.MN }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.MN_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.NA`]="{ item }">
                                <div v-if="item.NA_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.NA }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NA_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'NA',item.NA,$event,item.NA_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.NA }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NA_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.NH4`]="{ item }">
                                <div v-if="item.NH4_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.NH4 }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NH4_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'NH4',item.NH4,$event,item.NH4_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.NH4 }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NH4_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.NI`]="{ item }">
                                <div v-if="item.NI_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.NI }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NI_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'NI',item.NI,$event,item.NI_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.NI }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NI_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.NO2`]="{ item }">
                                <div v-if="item.NO2_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.NO2 }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NO2_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'NO2',item.NO2,$event,item.NO2_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.NO2 }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NO2_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.NO3`]="{ item }">
                                <div v-if="item.NO3_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.NO3 }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NO3_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'NO3',item.NO3,$event,item.NO3_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.NO3 }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NO3_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.OG_EDIBLE`]="{ item }">
                                <div v-if="item.OG_EDIBLE_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.OG_EDIBLE }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.OG_EDIBLE_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'OG_EDIBLE',item.OG_EDIBLE,$event,item.OG_EDIBLE_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.OG_EDIBLE }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.OG_EDIBLE_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.OG_MINERAL`]="{ item }">
                                <div v-if="item.OG_MINERAL_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.OG_MINERAL }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.OG_MINERAL_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'OG_MINERAL',item.OG_MINERAL,$event,item.OG_MINERAL_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.OG_MINERAL }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.OG_MINERAL_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.P`]="{ item }">
                                <div v-if="item.P_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.P }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.P_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'P',item.P,$event,item.P_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.P }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.P_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.PARAQUAT`]="{ item }">
                                <div v-if="item.PARAQUAT_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.PARAQUAT }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PARAQUAT_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'PARAQUAT',item.PARAQUAT,$event,item.PARAQUAT_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.PARAQUAT }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PARAQUAT_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.PB`]="{ item }">
                                <div v-if="item.PB_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.PB }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PB_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'PB',item.PB,$event,item.PB_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.PB }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PB_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.PCB`]="{ item }">
                                <div v-if="item.PCB_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.PCB }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PCB_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'PCB',item.PCB,$event,item.PCB_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.PCB }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PCB_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.PHENOL`]="{ item }">
                                <div v-if="item.PHENOL_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.PHENOL }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PHENOL_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'PHENOL',item.PHENOL,$event,item.PHENOL_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.PHENOL }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PHENOL_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.PO4_3`]="{ item }">
                                <div v-if="item.PO4_3_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.PO4_3 }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PO4_3_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'PO4_3',item.PO4_3,$event,item.PO4_3_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.PO4_3 }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PO4_3_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.RA226`]="{ item }">
                                <div v-if="item.RA226_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.RA226 }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.RA226_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'RA226',item.RA226,$event,item.RA226_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.RA226 }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.RA226_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.S`]="{ item }">
                                <div v-if="item.S_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.S }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.S_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'S',item.S,$event,item.S_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.S }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.S_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SALINITY`]="{ item }">
                                <div v-if="item.SALINITY_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.SALINITY }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SALINITY_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SALINITY',item.SALINITY,$event,item.SALINITY_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.SALINITY }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SALINITY_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SE`]="{ item }">
                                <div v-if="item.SE_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.SE }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SE_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SE',item.SE,$event,item.SE_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.SE }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SE_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SILICA`]="{ item }">
                                <div v-if="item.SILICA_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.SILICA }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SILICA_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SILICA',item.SILICA,$event,item.SILICA_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.SILICA }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SILICA_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SILVEX_2_4_5_TP`]="{ item }">
                                <div v-if="item.SILVEX_2_4_5_TP_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.SILVEX_2_4_5_TP }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SILVEX_2_4_5_TP_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SILVEX_2_4_5_TP',item.SILVEX_2_4_5_TP,$event,item.SILVEX_2_4_5_TP_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.SILVEX_2_4_5_TP }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SILVEX_2_4_5_TP_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SN`]="{ item }">
                                <div v-if="item.SN_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.SN }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SN_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SN',item.SN,$event,item.SN_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.SN }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SN_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SO4`]="{ item }">
                                <div v-if="item.SO4_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.SO4 }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SO4_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SO4',item.SO4,$event,item.SO4_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.SO4 }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SO4_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SOLAR_BATTERY`]="{ item }">
                                <div v-if="item.SOLAR_BATTERY_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.SOLAR_BATTERY }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SOLAR_BATTERY_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SOLAR_BATTERY',item.SOLAR_BATTERY,$event,item.SOLAR_BATTERY_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.SOLAR_BATTERY }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SOLAR_BATTERY_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.SR_90`]="{ item }">
                                <div v-if="item.SR_90_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.SR_90 }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SR_90_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'SR_90',item.SR_90,$event,item.SR_90_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.SR_90 }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SR_90_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.TC`]="{ item }">
                                <div v-if="item.TC_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.TC }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TC_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'TC',item.TC,$event,item.TC_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.TC }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TC_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.TDS`]="{ item }">
                                <div v-if="item.TDS_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.TDS }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TDS_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'TDS',item.TDS,$event,item.TDS_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.TDS }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TDS_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.TEMPERATURE`]="{ item }">
                                <div v-if="item.TEMPERATURE_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.TEMPERATURE }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TEMP_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'TEMPERATURE',item.TEMPERATURE,$event,item.TEMP_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.TEMPERATURE }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TEMP_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.TOC`]="{ item }">
                                <div v-if="item.TOC_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.TOC }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TOC_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'TOC',item.TOC,$event,item.TOC_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.TOC }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TOC_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.TRIOXONE_2_4_5_T`]="{ item }">
                                <div v-if="item.TRIOXONE_2_4_5_T_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.TRIOXONE_2_4_5_T }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TRIOXONE_2_4_5_T_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'TRIOXONE_2_4_5_T',item.TRIOXONE_2_4_5_T,$event,item.TRIOXONE_2_4_5_T_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.TRIOXONE_2_4_5_T }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TRIOXONE_2_4_5_T_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.TURBIDITY`]="{ item }">
                                <div v-if="item.TURBIDITY_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.TURBIDITY }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TURBIDITY_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'TURBIDITY',item.TURBIDITY,$event,item.TURBIDITY_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.TURBIDITY }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TURBIDITY_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.U`]="{ item }">
                                <div v-if="item.U_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.U }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.U_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'U',item.U,$event,item.U_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.U }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.U_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.VELOCITY`]="{ item }">
                                <div v-if="item.VELOCITY_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.VELOCITY }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.VELOCITY_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'VELOCITY',item.VELOCITY,$event,item.VELOCITY_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.VELOCITY }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.VELOCITY_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.ZN`]="{ item }">
                                <div v-if="item.ZN_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.ZN }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.ZN_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'ZN',item.ZN,$event,item.ZN_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.ZN }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.ZN_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.PAH_HYDROCARBON`]="{ item }">
                                <div v-if="item.PAH_HYDROCARBON_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.PAH_HYDROCARBON }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PAH_HYDROCARBON_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'PAH_HYDROCARBON',item.PAH_HYDROCARBON,$event,item.PAH_HYDROCARBON_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.PAH_HYDROCARBON }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PAH_HYDROCARBON_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.OIL_IN_WATER`]="{ item }">
                                <div v-if="item.OIL_IN_WATER_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.OIL_IN_WATER }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.OIL_IN_WATER_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'OIL_IN_WATER',item.OIL_IN_WATER,$event,item.OIL_IN_WATER_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.OIL_IN_WATER }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.OIL_IN_WATER_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.WATER_LEVEL`]="{ item }">
                                <div v-if="item.WATER_LEVEL_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.WATER_LEVEL }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.WATER_LEVEL_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'WATER_LEVEL',item.WATER_LEVEL,$event,item.WATER_LEVEL_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.WATER_LEVEL }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.WATER_LEVEL_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <!-- TAMBAHAN PARAM! -->
                            <template v-slot:[`item.TRYPHPTOPHAN`]="{ item }">
                                <div v-if="item.TRYPHPTOPHAN_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.TRYPHPTOPHAN }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TRYPHPTOPHAN_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'TRYPHPTOPHAN',item.TRYPHPTOPHAN,$event,item.TRYPHPTOPHAN_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.TRYPHPTOPHAN }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TRYPHPTOPHAN_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.NH3`]="{ item }">
                                <div v-if="item.NH3_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.NH3 }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NH3_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'NH3',item.NH3,$event,item.NH3_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.NH3 }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NH3_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:[`item.TOTAL_AMMONIA`]="{ item }">
                                <div v-if="item.TOTAL_AMMONIA_STAT">
                                    <v-btn color="#02b2c3" tile elevation="0"  class="">
                                    {{ item.TOTAL_AMMONIA }} 
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TOTAL_AMMONIA_FLAG }}</p>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn @click="collectDataTD(item.STATION_ID,item.DATETIME,'TOTAL_AMMONIA',item.TOTAL_AMMONIA,$event,item.TOTAL_AMMONIA_FLAG)" tile elevation="0"  class="btn-click-td">
                                    {{ item.TOTAL_AMMONIA }}  
                                        <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TOTAL_AMMONIA_FLAG }}</p>
                                    </v-btn>
                                </div>
                            </template>


                            <!-- Slot Parameter End -->






                        </v-data-table>

                        <div id="container_btnDpn_valReport" class="center-el" style="text-align:center;">
                            <v-btn
                           
                            class="btn-after-table button white--text"
                            @click="submitDataTD"
                            >
                                SUBMIT
                            </v-btn>

                            <v-btn
                            class="btn-after-table error white--text"
                            @click="cancelSelectTD"
                            >
                                CANCEL
                            </v-btn>
                        </div>

                    </div>


                    <div id="pageLepasSubmitSelectedTD">

                        <v-data-table
                        :headers="thead_fromTD_validation_report"
                        :items="tbody_fromTD_validation_report"
                        :header-props="{ sortIcon: null }"
                        class="elevation-1 tableValidationReport headerDtSarawak"
                        disable-items-per-page
                        disable-pagination
                        no-select-on-click
                        >
                            <template v-slot:[`item.datetime`]="{ item }">
                                <!-- {{ item.DATETIME }} -->
                                <span v-html="item.datetime"></span>
                            </template>

                        </v-data-table>

                        <div class="remarksTd" style="text-align:center;">

                            <v-textarea
                            class="textarea-cust"
                            outlined
                            label="Remarks"
                            @input="inputRemarksDataTD($event)"
                            ></v-textarea>

                            <v-btn
                            class="btn-after-table button white--text"
                            @click="submitRemarksDataTD"
                            :loading="submitRemarksDataTDLoading"
                            >
                                SUBMIT
                            </v-btn>

                            <v-btn
                            class="btn-after-table error white--text"
                            @click="backToMainValidationReport"
                            >
                                CANCEL
                            </v-btn>
                        </div>

                    </div>


                    <!-- Popups/Dialogs Section Tab Report-->
                    <div class="text-center">
                        <v-dialog
                        v-model="dialogSucceedValidateReport"
                        width="500"
                        persistent
                        >

                        <v-card>
                            <!--<v-card-title class="text-h5" style="background:#106f79;color:white;">
                            Success! <v-icon style="color:white;margin-left:15px;">mdi-file-clock-outline</v-icon>
                            </v-card-title>-->

                            <v-card-title class="text-h5 white--text button">
                                <v-icon class="white--text mr-2">mdi-text-box-check</v-icon>Validation successful
                            </v-card-title>

                            <v-card-text class="pa-5 d-flex justify-center">
                                <h3> Your request submitted successfully and pending for approval confirmation</h3> 
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                @click="dialogSucceedValidateReport = false; generateTable();"
                            >
                                Close
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </div>

                </v-tab-item>

                <!-- Content Tab Validation Pending -->
                <v-tab-item :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">

                    <v-subheader class="subTitle_ black--text font-weight-bold">
                        DATA VALIDATION PENDING LIST FOR {{stationID}} - {{convertUcase(this.locationStation)}} FROM {{ convertDateFormat(dateFrom) }} TO {{ convertDateFormat(dateTo) }}
                    </v-subheader>

                    <!-- Button Export Table -->
                    <div class="section-btn-export">
                        <!-- <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="excel"
                            elevation="2"
                            v-bind="attrs"
                            v-on="on"
                            @click="exportReport()"
                            class="btn-export-table btn-excel"
                            >
                                <v-icon
                                color="white"
                                center
                                dark
                                >
                                    mdi-microsoft-excel
                                </v-icon>
                            </v-btn>
                            </template>
                            <span>Export to Excel</span>
                        </v-tooltip> -->
                    </div>

                    <!-- Table Pending-->
                    <v-data-table
                    v-model="selected"
                    :headers="thead_validation_pending"
                    :items="tbody_validation_pending"
                    :header-props="{ sortIcon: null }"
                    class="elevation-1 tableValidationPending headerDtSarawak headerDesktop"
                    :items-per-page="5"
                    no-select-on-click
                    :mobile-breakpoint="0"
                    >

                        <template v-slot:[`item.datetime`]="{ item }">
                            <span v-html="item.datetime"></span>
                        </template>

                        <template v-slot:[`item.requestedDt`]="{ item }">
                            <span v-html="item.requestedDt"></span>
                        </template>

                        <template v-slot:[`item.actionDropdown`]="{ item }">
                            <!-- {{ item.actionDropdown }} -->
                            <v-container>
                                <v-select
                               
                                :items="item.actionDropdown"
                                v-model="item.modelActionDropdown"
                                class="action-chips"
                                small
                                dense
                                small-chips
                                outlined
                                :disabled="item.requestedBy == whichUser"
                                v-on:change="actionPending(item.id,$event)"
                                >
                                </v-select>
                            </v-container>
                        </template>

                        <template v-slot:[`item.actionRemarks`]="{ item }">
                            <v-textarea
                            
                            id="actionRemarksPending"
                            v-model="item.actionRemarks"
                            small
                            dense
                            outlined
                            full-width
                            rows="2"
                            :disabled="item.requestedBy == whichUser"
                            @input="getdataTextArea(item.id,$event)"
                            >
                            </v-textarea>
                        </template>

                        <template v-slot:[`item.submitCheckbox`]="{ item }">
                            <v-simple-checkbox
                           
                            class="checkbox-dt"
                            :value="item.id"
                            v-model="item.submitCheckbox"
                            v-ripple
                            :disabled="item.requestedBy == whichUser"
                            @click="check(item.actionRemarks,item.id,item.submitCheckbox,$event,item.modelActionDropdown)"
                            ></v-simple-checkbox>
                        </template>

                    </v-data-table>

                    <div class="center-el" style="text-align:center;" >
                        <v-btn
                        :disabled="fordv == false"
                        class="btn-after-table button white--text"
                        @click="submitDataPending"
                        >
                            SUBMIT
                        </v-btn>

                        <!-- <v-btn
                        id="toggleChkUnck"
                        class="btn-after-table primary"
                        @click="checkingAll"
                        >
                            Select/Deselect All
                        </v-btn> -->
                    </div>


                    <!-- Popups/Dialogs Succeed Section Tab Pending -->
                    <div class="text-center">
                        <v-dialog
                        v-model="dialogSucceedPending"
                        width="500"
                        >

                        <v-card>
                            <v-card-title class="text-h5" style="background:#106f79;color:white;">
                            Success! <v-icon style="color:white;margin-left:15px;">mdi-text-box-check-outline</v-icon>
                            </v-card-title>

                            <v-card-text style="padding:20px 24px;font-weight:bold;">
                            All data selected submitted successfully.
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="alreadySuccessSubmitPending"
                            >
                                OK
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </div>


                    <!-- Popups/Dialogs Error Section Tab Pending -->
                    <div class="text-center">
                        <v-dialog
                        v-model="dialogErrorPending"
                        width="500"
                        >

                        <v-card>
                            <v-card-title class="text-h5 red lighten-2" style="color:white;">
                            Warning! <v-icon style="color:white;margin-left:15px;">mdi-alert</v-icon>
                            </v-card-title>

                            <v-card-text style="padding:20px 24px;font-weight:bold;">
                            Please make sure action field was selected.
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                style="color:red !important;"
                                color="primary"
                                text
                                @click="dialogErrorPending = false"
                            >
                                OK
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </div>
                    
                </v-tab-item>

                <!-- Content Tab Validation History -->
                <v-tab-item :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">

                    <v-subheader class="subTitle_ black--text font-weight-bold">
                        DATA VALIDATION HISTORY RECORD FOR {{stationID}} - {{ convertUcase(this.locationStation) }} FROM {{ convertDateFormat(dateFrom) }} TO {{ convertDateFormat(dateTo) }}
                    </v-subheader>

                    <!-- Button Export Table -->
                    <div class="section-btn-export">
                        <!-- <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="excel"
                            elevation="2"
                            v-bind="attrs"
                            v-on="on"
                            @click="exportReport()"
                            class="btn-export-table btn-excel"
                            >
                                <v-icon
                                color="white"
                                center
                                dark
                                >
                                    mdi-microsoft-excel
                                </v-icon>
                            </v-btn>
                            </template>
                            <span>Export to Excel</span>
                        </v-tooltip> -->
                    </div>

                    <!-- Table History-->
                    <v-data-table
                    :headers="thead_validation_history"
                    :items="tbody_validation_history"
                    :header-props="{ sortIcon: null }"
                    class="elevation-1 tableValidationHistory headerDtSarawak headerDesktop"
                    :items-per-page="5"
                    no-select-on-click
                    :mobile-breakpoint="0"
                    >

                        <template v-slot:[`item.datetime`]="{ item }">
                            <span v-html="item.datetime"></span>
                        </template>

                        <template v-slot:[`item.requestedDt`]="{ item }">
                            <span v-html="item.requestedDt"></span>
                        </template>

                        <template v-slot:[`item.approvedDt`]="{ item }">
                            <span v-html="item.approvedDt"></span>
                        </template>

                    </v-data-table>
                    
                </v-tab-item>

            </v-tabs-items>

            

        </v-container>
        

        <!-- Footer Legend -->
        <template >
            <v-footer padless :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">

            <v-col
            class="text-center footer-report"
            cols="12"
            >
                <span class="one-text-footer mx-5">
                    <span class="red--text">C</span>
                    <span class="blue--text">=CALIBRATION</span>
                </span>
                <span class="one-text-footer mx-5">
                    <span class="red--text">M</span>
                    <span class="blue--text">=MAINTENANCE</span>
                </span>
                <span class="one-text-footer mx-5 dontBreak">
                    <span class="red--text">DOR</span>
                    <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
                </span><br>
                <span class="one-text-footer mx-5">
                    <span class="red--text">OUTL</span>
                    <span class="blue--text">=OUTLIER</span>
                </span>
                <span class="one-text-footer mx-5">
                    <span class="red--text">CONST</span>
                    <span class="blue--text">=CONSTANT READING</span>
                </span>
                <span class="one-text-footer mx-5">
                    <span>NA</span>
                    <span class="blue--text">=DATA NOT AVAILABLE</span>
                </span>
            </v-col>
            </v-footer>
        </template>

    </v-container>


    

</template>

<script>
import axios from 'axios';
import Papa from "papaparse";

export default {
    data: () => ({

        GlobalRawData: [],

        itemPerPageReport: 10,
        
        
        pathModul: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
                // href: '/Mapvsadiewer',
            },
            {
                text: 'Data Validation',
                disabled: true,
                href: '/Operational/Validation',
            },
        ],
        itemStation: [],
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modalCalendar: false,
        menu2: false,
        modalCalendar2: false,
        pen_bg: null,
        myColor: ["red", "yellow", "blue"],
        // Variable/Object for value filtering
        valStation : null,
        valDate : null,
        fordv: false,
 
        // Title - Subtitle After Generate Data
        modelStationSelected: "",
        stationID: null,
        locationStation: null,

        tabs: null,
        tabsTypeValidation: [
            {tab: "QAQC Data Validation Report"},
            {tab: "QAQC Data Validation Pending"},
            {tab: "QAQC Data Validation History"}
        ],


        // Table
        loadingTable: false,
        loadingText: "",
        dateHeader: {
            text: 'Datetime',
            sortable: true,
            value: 'DATETIME',
            divider: true,
            align: 'center',
        },
        stationIdHeader: {
            text: 'Station ID',
            sortable: false,
            value: 'STATION_ID',
            divider: true,
            align: 'center',
        },

        thead_validation_report: [],
        tbody_validation_report: [],
        thead_fromTD_validation_report: [
            {text: 'Station ID', value: 'stationId', sortable: true, divider: true, align: 'center'},
            {text: 'Datetime', value: 'datetime', sortable: true, divider: true, align: 'center'},
            {text: 'Parameter', value: 'parameter', sortable: true, divider: true, align: 'center'},
            {text: 'Value', value: 'value', sortable: true, divider: true, align: 'center'},
        ],
        tbody_fromTD_validation_report: [],
        allDataFlag: [],
        textAreaValidationReport: "",
        dataAddVal: [],
        submitRemarksDataTDLoading: false,

        thead_validation_pending: [
            {text: 'Station ID', value: 'stationId', sortable: true, divider: true, align: 'center'},
            {text: 'Datetime', value: 'datetime', sortable: true, divider: true, align: 'center'},
            {text: 'Parameter', value: 'parameter', sortable: true, divider: true, align: 'center'},
            {text: 'Value', value: 'value', sortable: true, divider: true, align: 'center'},
            {text: 'Status', value: 'status', sortable: true, divider: true, align: 'center'},
            {text: 'Requester Remarks', value: 'requesterRemarks', sortable: true, divider: true, align: 'center'},
            {text: 'Requested By', value: 'requestedBy', sortable: true, divider: true, align: 'center'},
            {text: 'Requested Date', value: 'requestedDt', sortable: true, divider: true, align: 'center'},
            {text: 'Action', value: 'actionDropdown', sortable: false, divider: true, align: 'center'},
            {text: 'Action By Remarks', value: 'actionRemarks', sortable: false, divider: true, align: 'center'},
            {text: 'Submission', value: 'submitCheckbox', sortable: false, divider: true, align: 'center'},
        ],
        tbody_validation_pending: [],
        actionApprove: [],
        actionReject: [],
        actionForPending: [],
        remarksTextArea: [],
        selected: [],
        jsonForm: [],
        dialogSucceedValidateReport: false,
        dialogSucceedPending: false,
        dialogErrorPending: false,
        click_track: 1,
        whichUser: '',
        // thisUser: '',
        selectedPendingToHistory: [],
        generalUser: false,
        thead_validation_history: [
            {text: 'Station ID', value: 'stationId', sortable: true, divider: true, align: 'center'},
            {text: 'Datetime', value: 'datetime', sortable: true, divider: true, align: 'center'},
            {text: 'Parameter', value: 'parameter', sortable: true, divider: true, align: 'center'},
            {text: 'Value', value: 'value', sortable: true, divider: true, align: 'center'},
            {text: 'Status', value: 'status', sortable: true, divider: true, align: 'center'},
            {text: 'Requester Remarks', value: 'requesterRemarks', sortable: true, divider: true, align: 'center'},
            {text: 'Requested By', value: 'requestedBy', sortable: true, divider: true, align: 'center'},
            {text: 'Requested Date', value: 'requestedDt', sortable: true, divider: true, align: 'center'},
            {text: 'Resolve By', value: 'approvedBy', sortable: false, divider: true, align: 'center'},
            {text: 'Resolve By Remarks', value: 'approverRemarks', sortable: false, divider: true, align: 'center'},
            {text: 'Resolve Datetime', value: 'approvedDt', sortable: false, divider: true, align: 'center'},
        ],
        tbody_validation_history: [],

        exportTableParamReport: [],
        exportTableParamPending: [],
        exportTableParamHistory: [],
        stat_val: []
        // stringText: ''

    }),

    computed: {
    itemsWithIndex(){
      return this.items.map((item, index) => ({ ...item, index:index }))
    }
  },

    methods: {

        convert12Hours(time) {
          var H = +time.substr(0, 2);
          var h = H % 12 || 12;
          var ampm = (H < 12 || H === 24) ? "AM" : "PM";
          time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
          return time;
      },

      convertDateFormat(date){
          var dd = date.split("-")[2];
          var mm = date.split("-")[1];
          var yyyy = date.split("-")[0];
          date = dd + "/" + mm + "/" + yyyy;
          return date;
      },
        
      converttoDBFormat(date){

        let tar = date;
        let dt;

        if (tar.includes(" AM")){
            dt = tar.replace(" AM", "_AM")
        } else {
            dt = tar.replace(" PM", "_PM")
        }

        dt = dt.split(" ")
        let tarikh = dt[0];
        let masa = dt[1];



        if (masa.includes("_AM")){
            if (masa.split(":")[0] < 10) {
                masa = "0"+masa.replace("_AM","")
            } else if (masa.split(":")[0] == 12) {
                masa = masa.replace("_AM","").replace("12","00")
            }
            else {
                    masa = masa.replace("_AM","")
            }
        } else {
            masa = masa.replace("_PM","")
            if (masa.split(":")[0] != "12") {
                masa = (parseInt(masa.split(":")[0]) + 12) + ":" + masa.split(":")[1] + ":" + masa.split(":")[2]
            }
        }

        // console.log("masa",masa)

        tarikh = tarikh.split("/")[2] + "-" + tarikh.split("/")[1] + "-" + tarikh.split("/")[0]

        date = tarikh + "T" + masa
        return date;

        // console.log(date)
        },

        convertUcase(text){

        // console.log(text)

        if (text != null){

        let t_ucase = text.toUpperCase();

        return t_ucase;

        }
        },

        load(){
            this.getStation();
            this.whichUser = this.$store.getters.user.email;
            let thisUser = this.$store.getters.user.dataValidationStatus;

            // console.log("wu",this.thisUser)
            if(thisUser === 1){
            this.fordv = true;
            }

            else{
            this.fordv = false;
            }
        },

        getStation(){
            axios.get(this.globalUrl+'bakaj/stations', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                this.itemStation = [];

                for (let i = 0; i < response.data.length; i++) {
                    this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);  
                }
                
            })
            .catch(error => {
                console.log(error);
            })
        },

        // Get value from Selected Station
        selectValueStation: function(e) {
            // console.log(e);
            this.valStation = e;
        },


        getAllParam(){

            // console.log(this.stationID);

            axios.get(this.globalUrl+'bakaj/params?stationid='+this.stationID+'&subindex=0', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                
                let keys = [
                    'text',
                    'value',
                    'divider',
                    'align',
                ],
                result = response.data.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

                // console.log("result",result)

                this.thead_validation_report = result;
                this.thead_validation_report.unshift(this.stationIdHeader);
                this.thead_validation_report.unshift(this.dateHeader);

                this.getRawData(this.stationID,this.dateFrom,this.dateTo);
                
            })
            .catch(error => {
                console.log(error);
            })
        },


        getRawData(station,dateFrom,dateTo){
            // axios.get(this.globalUrl+'bakaj/rawdata?startdt='+dateFrom+'&enddt='+dateTo+'&stationid=all', {
            //     headers: {
            //         'Authorization': 'Bearer ' + this.$store.getters.user.token,
            //     }
            // })
            axios.get(this.globalUrl+'bakaj/readingval?startdt='+dateFrom+'&enddt='+dateTo+'&stationid='+station, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                this.loadingTable = false;
                this.loadingText = "";
                // console.log(response.data);
                this.GlobalRawData = response.data

                let keyFlag = [];
                let valueFlagNotNull = [];
                for (let i = 0; i < response.data.length; i++) {
                    

                    // response.data[i]["DATETIME"] = response.data[i]["DATETIME"].replaceAll('T', '<br>');
                    // tukar reading
                    
                    // console.log("ddd",response.data)

                    // if (response.data[i].DATETIME ) 
                    let keyData = Object.keys(response.data[0])
                    for (let x = 0; x < keyData.length; x++) {
                        
                        if (keyData[x].includes("FLAG")) {
                            // console.log(keyData[x]);
                            if (response.data[i][keyData[x]] == "NA" || response.data[i][keyData[x]] == null) {
                                response.data[i][keyData[x]] = null;
                            }
                            else{
                                // console.log("flag not null");
                                // console.log(response.data[i][keyData[x]]);
                                valueFlagNotNull.push(response.data[i][keyData[x]]);
                            }

                            keyFlag.push(keyData[x]);

                            

                        }
                        else{

                            // console.log(keyData[x]);

                            if (response.data[i][keyData[x]] == null) {
                                response.data[i][keyData[x]] = "NA";
                            }
                            
                            if(response.data[i][keyData[x]+"_FLAG"] != null || response.data[i][keyData[x]+"_flag"] != null
                            || response.data[i][keyData[x].split("_")[0]+keyData[x].split("_")[1]+"_FLAG"] != null
                            || response.data[i][keyData[x].split("_")[0]+keyData[x].split("_")[1]+"_flag"] != null
                            ){
                                response.data[i][keyData[x]] = null;
                                // console.log(response.data[i][keyData[x]]);
                            }

                            if (typeof response.data[i][keyData[x]] == "number") {

                                // console.log(keyData[x])

                            if(keyData[x] == "TSS" || keyData[x] == "TURBIDITY"){
                                response.data[i][keyData[x]] = response.data[i][keyData[x]].toFixed(1);
                                }

                            else if(keyData[x] == "OIL_IN_WATER" || keyData[x] == "PAH_HYDROCARBON" || keyData[x] == "NH3"){
                                response.data[i][keyData[x]] = response.data[i][keyData[x]].toFixed(4);
                            }

                            else{
                                response.data[i][keyData[x]] = response.data[i][keyData[x]].toFixed(2);
                                }
                            }

                        }

                        // response.data[i][keyData[x]+"_STAT"] = false
                    }

                    
                    // console.log(response.data[i][this.stat_val[0]["parameter"]], "sddjdj")

                    // if (response.data[i]["station_a"] == station) {

                        // console.log("sv",response.data[i])
                        // console.log("1", response.data[1][this.stat_val[0]["parameter"]])
                        // console.log("2", this.stat_val[0]["value"])
                        // console.log("3", response.data[1]["DATETIME"])
                        // console.log("4", this.converttoDBFormat(this.stat_val[0]["dt"]))
    
                        for(let k=0; k < this.stat_val.length; k++){
                            
                            response.data[i][this.stat_val[k]["parameter"]+"_STAT"] = false

                            // console.log(response.data[i]["NH3"])
                            // console.log(this.stat_val[k]["value"])

                            if( response.data[i][this.stat_val[k]["parameter"]] === this.stat_val[k]["value"] && response.data[i]["DATETIME"] === this.stat_val[k]["dt"]){

                                response.data[i][this.stat_val[k]["parameter"]] = response.data[i][this.stat_val[k]["parameter"]] 

                                // response.data[i][this.stat_val[0]["parameter"]].style.backgroundColor = "#0199be";
                                // this.pen_bg = "yes"
                                response.data[i][this.stat_val[k]["parameter"]+"_STAT"] = true
                            }
                            
                            // console.log(response.data[i]["TEMPERATURE_STAT"])
                        }

                        

                        // let keyData = Object.keys(response.data[0]);

                        // baru tambah

                        for (let x = 0; x < keyData.length; x++) {

                        if(keyData[x].includes("FLAG") == false) {
                        if (response.data[i][keyData[x]] == null || response.data[i][keyData[x]] == "NULL" || response.data[i][keyData[x]] == "null") {
                            response.data[i][keyData[x]] = "NA";
                        } 
                        
                        else {
                            if (keyData[x] != "SI_DO_SAT" && keyData[x] != "SI_BOD" && keyData[x] != "SI_COD" && keyData[x] != "SI_NH3N" && keyData[x] != "SI_TSS" && keyData[x] != "SI_pH") 
                            {
                            if (typeof response.data[i][keyData[x]] == "number") {

                                if(keyData[x] == "TSS" || keyData[x] == "TURBIDITY"){
                                response.data[i][keyData[x]] = response.data[i][keyData[x]].toFixed(1);
                                }

                                else{
                                response.data[i][keyData[x]] = response.data[i][keyData[x]].toFixed(2);
                                }
                            }
                            }
                        }

                        if(response.data[i].WQI < 0 || response.data[i].WQI == null || response.data[i].WQI == "NULL" || response.data[i].WQI == "null") {
                            response.data[i].WQI = "NA";
                        }
                        }
                        }

                        // sampai sini
                      
                        response.data[i]["DATETIME"] = this.convertDateFormat(response.data[i]["DATETIME"].split("T")[0])  + " " + this.convert12Hours(response.data[i]["DATETIME"].split("T")[1]);

                        // console.log("tets",this.converttoDBFormat(response.data[i]["OIL_IN_WATER"]))

                        this.tbody_validation_report.push(response.data[i]);
                        // } 
                    
                    // console.log("aaa",response.data[0]["NH3"])
                    
                        

                    // this.loadingTable = false;
                    // this.loadingText = "";
                    // console.log(this.pen_bg)
                }



                let keys = keyFlag,
                result = response.data.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

                this.allDataFlag.push(result);

                // console.log(this.allDataFlag);

                // this.changeColor();

                
                
            })
            .catch(error => {
                console.log(error);
            })

        },

        getDataValidation(station,dateFrom,dateTo){

            // console.log(station);
            // console.log(dateFrom);
            // console.log(dateTo);

            axios.get(this.globalUrl+'maint/dataval?stationid='+station+'&startdt='+dateFrom+'T00:00:00&&enddt='+dateTo+'T23:59:59', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
               
                this.stat_val = [];
                // if(response.data.length == 0){
                //     console.log("Tiada Data");
                // }
                if(response.data.length > 0){

                    response.data.forEach(function(res){
                        // Add Attributes For Validation Pending
                        res["stationId"] = station;
                        res["requestedDt"] = res["requestedDt"].replaceAll('T', '_');
                        res["datetime"] = res["datetime"].replaceAll('T', '_');
                        res["actionDropdown"] = ["Approve","Reject"];
                        res["modelActionDropdown"] = "";
                        res["actionRemarks"] = "";
                        res["submitCheckbox"] = false;

                        // if (res["approveddt"] != null) {
                        //     res["approveddt"] = res["approveddt"].replaceAll('T', '_'); 
                        // }

                        // console.log("r2",res["requestedBy"]);
                    });

                    console.log("r",response.data);

                    for (let i = 0; i < response.data.length; i++) {

                        
                        // response.data[i]["stationid"] = station;

                        if (response.data[i]["parameter"] === "TSS" || response.data[i]["parameter"] === "TURBIDITY"){

                            response.data[i]["value"] = response.data[i]["value"].toFixed(1)

                        }

                        else if(response.data[i].parameter == "OIL_IN_WATER" || response.data[i].parameter == "PAH_HYDROCARBON" || response.data[i].parameter == "NH3"){
                                response.data[i].value = response.data[i]["value"].toFixed(4);
                            }

                        else {

                            response.data[i]["value"] = response.data[i]["value"].toFixed(2)

                        }


                        response.data[i]["datetime"] = this.convertDateFormat(response.data[i]["datetime"].split("_")[0])  + " " + this.convert12Hours(response.data[i]["datetime"].split("_")[1]);

                        response.data[i]["requestedDt"] = this.convertDateFormat(response.data[i]["requestedDt"].split("_")[0])  + " " + this.convert12Hours(response.data[i]["requestedDt"].split("_")[1]); 

                        if ( response.data[i]["approvedDt"] != null){
                            response.data[i]["approvedDt"] = this.convertDateFormat(response.data[i]["approvedDt"].split("T")[0])  + " " + this.convert12Hours(response.data[i]["approvedDt"].split("T")[1]); 
                        }
                        
                        console.log("sss",response.data[i]["datetime"])
                        let dtime = this.converttoDBFormat(response.data[i]["datetime"])

                        //  console.log("rd", response.data[i])
                        if (response.data[i].status === "pending") {

                            let values = response.data[i].value

                            // console.log(typeof values)

                            // if (typeof values == "number") {

                                // console.log("masuksini")
                            // if(response.data[i].parameter == "OIL_IN_WATER" || response.data[i].parameter == "PAH_HYDROCARBON" || response.data[i].parameter == "NH3"){
                            //     response.data[i].value = parseInt(values).toFixed(4);
                            // }
                        // }

                            let obj_stat = {
                            parameter: response.data[i].parameter,
                            value: response.data[i].value,
                            status: response.data[i].status,
                            dt: dtime
                                }

                                this.stat_val.push(obj_stat);


                            this.tbody_validation_pending.push(response.data[i]);
                        }
                        else {
                            this.tbody_validation_history.push(response.data[i]);
                        }

                        // console.log(this.stat_val)

                    }
                    

                }

                // console.log(this.$store.getters.user.email);

                
                this.getAllParam();

                

                

                
            })
            .catch(error => {
                console.log(error);
            })

            
        },

        

        generateTable(){

            this.loadingTable = true;
            this.loadingText = "Data Loading... Please wait";

            // Keluarkan Details SubText
            this.stationID = this.valStation.split(' - ')[0];
            this.locationStation = this.valStation.split(' - ')[1];
            this.$store.getters.user.role == 3 ? this.generalUser == true : this.generalUser == false; 

            // Unhide checkbox on header
            document.querySelector(".subTitle").style.display = "block";
            // let chk = document.querySelectorAll(".checkbox-header-dailyReport")
            // for (let i = 0; i < chk.length; i++) {
            // chk[i].style.display = "contents";
            // }

            // Clear First all Array Thead and Tbody
            this.thead_validation_report = [];
            this.tbody_validation_report = [];

            this.tbody_validation_pending = [];

            this.tbody_validation_history = [];

            this.exportTableParamReport = [];
            this.exportTableParamPending = [];

            
            this.getDataValidation(this.stationID,this.dateFrom,this.dateTo);

        },

        collectDataTD(station,dt,param,val,clickOnElement,dataFlag){

            console.log(station);
            console.log(dt);
            console.log(param);
            console.log(val);
            console.log(clickOnElement);
            console.log(dataFlag);


            // console.log(this.tbody_fromTD_validation_report);

            // UNCOMMENT code below if want only one td can be selected at a time ---------------------
            // document.querySelectorAll(".btn-click-td > span").forEach(function(res){
            //     res.style.backgroundColor = "white";
            //     res.style.color = "black";
            // })


            // console.log(val,dataFlag);

            // console.log(clickOnElement);
            // console.log(clickOnElement.srcElement);

            // dt = this.convertDateFormat(dt.split("T")[0])  + " " + this.convert12Hours(dt.split("T")[1]);

            // console.log("dt2", dt)


            if (clickOnElement.target.className == "val-flag") {
                // clickOnElement.path[1].style.backgroundColor = "#0199be";
                // clickOnElement.path[1].style.color = "white"; 
                
                console.log("already deleted");
            }
            else{

                console.log("baru nak delete");

                if (dataFlag != null || val == "NA") {
                    console.log("nothing happen");
                }
                else{
                    

                    this.tbody_fromTD_validation_report.push({
                        stationId: station,
                        datetime: dt,
                        parameter: param,
                        value: val
                    });

                    clickOnElement.target.style.backgroundColor = "#0199be"; // blue highlight
                    // clickOnElement.target.style.backgroundImage = "linear-gradient(to top, #0081BA, #02B3C3)";
                    clickOnElement.target.style.color = "white";

                    document.getElementById("container_btnDpn_valReport").style.display = "block";
                }
                
            }

            // console.log(this.tbody_fromTD_validation_report);



            // Display Button Submit & Button Cancel
            
   
        },

        submitDataTD(){
            document.getElementById("pageDepanValidationReport").style.display = "none";
            document.getElementById("pageLepasSubmitSelectedTD").style.display = "block";
        },

        cancelSelectTD(){

            this.tbody_fromTD_validation_report = [];

            document.querySelectorAll(".btn-click-td > span").forEach(function(res){
                res.style.backgroundColor = "white";
                res.style.color = "black";
            })
            

            document.getElementById("container_btnDpn_valReport").style.display = "none";
        },

        
        inputRemarksDataTD(textArea){
            // console.log(textArea);
            this.textAreaValidationReport = textArea;
        },

        submitRemarksDataTD(){
            // console.log(this.textAreaValidationReport);
            

            this.dataAddVal = [];

            // console.log(this.tbody_fromTD_validation_report.length)

            for (let i = 0; i < this.tbody_fromTD_validation_report.length; i++) {
                this.tbody_fromTD_validation_report[i]["RequesterRemarks"] = this.textAreaValidationReport.toString();
                this.tbody_fromTD_validation_report[i]["datetime"] = this.tbody_fromTD_validation_report[i]["datetime"].replaceAll('<br>', 'T');
                
                // if (this.tbody_fromTD_validation_report[0]["value"] == "NA") {
                //     this.tbody_fromTD_validation_report[0]["value"] = null;
                // }

                // console.log(this.tbody_fromTD_validation_report[0]);

                this.dataAddVal.push(this.tbody_fromTD_validation_report[i]);
            }

            // console.log("dv",this.dataAddVal);

            this.dataAddVal.forEach(function(res){
                // res["datetime"] = this.convert24Hours(res["datetime"].split("T")[1]);
                // res["datetime"] = res["datetime"].split("T")[1]
                // console.log(converttoDBFormat(res["datetime"]));
            }) // takde tambah ni kat bakaj

            // console.log(this.converttoDBFormat(this.dataAddVal[0]["datetime"]));
            // for(let i in this.dataAddVal){
            //     this.dataAddVal[i]["datetime"] = this.converttoDBFormat(this.dataAddVal[i]["datetime"])
               
            // }

             for (let i = 0; i < this.dataAddVal.length; i++) {
                    this.dataAddVal[i]["datetime"] = this.converttoDBFormat(this.dataAddVal[i]["datetime"]);
                    //  this.dataAddVal[i]["requestedDt"] = this.converttoDBFormat(this.dataAddVal[i]["requestedDt"])
             }

            // for(let i in this.dataAddVal){
            //     this.dataAddVal[i]["requestedDt"] = this.converttoDBFormat(this.dataAddVal[i]["requestedDt"])
               
            // }
            

            let jsonData = JSON.stringify(this.dataAddVal);

            console.log(jsonData);
            this.submitRemarksDataTDLoading = true;

            axios.post(this.globalUrl+'maint/adddataval', 
            jsonData,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                this.submitRemarksDataTDLoading = false;
                // document.getElementById("generateReport").click();
                this.dialogSucceedValidateReport = true;
                this.backToMainValidationReport();
                this.cancelSelectTD();
            },
            // this.backToMainValidationReport(),
            // this.cancelSelectTD()
            )
            .catch(error => {
                console.log(error);
            })


        },


        backToMainValidationReport(){
            document.getElementById("pageDepanValidationReport").style.display = "block";
            document.getElementById("pageLepasSubmitSelectedTD").style.display = "none";
        },


        // Get value from Action Approval Pending
        actionPending: function(id,action) {
            // console.log(id,action);

            for (let i = 0; i < this.selected.length; i++) {
                if (this.selected[i].split(" ~ ")[0] == id) {
                    this.selected[i] = this.selected[i].split(" ~ ")[0] + " ~ " + this.selected[i].split(" ~ ")[1] + " ~ " + action + " ~ " + this.selected[i].split(" ~ ")[3] + " ~ " + this.selected[i].split(" ~ ")[4]  + " - " + this.selected[i].split(" ~ ")[5]  + " ~ " + this.selected[i].split(" ~ ")[6];      
                    // console.log(this.selected[i]);
                }
            }
        },

        // Get value from Text Area Pending
        getdataTextArea: function(id,textArea) {
            // console.log(id,textArea);

            for (let i = 0; i < this.selected.length; i++) {
                if (this.selected[i].split(" ~ ")[0] == id) {
                    this.selected[i] = this.selected[i].split(" ~ ")[0] + " ~ " + this.selected[i].split(" ~ ")[1] + " ~ " + this.selected[i].split(" ~ ")[2] + " ~ " + textArea + " ~ " + this.selected[i].split(" ~ ")[4]  + " ~ " + this.selected[i].split(" ~ ")[5]  + " ~ " + this.selected[i].split(" ~ ")[6];
                    // console.log(this.selected[i]);
                }
            }
        },

        // Get all data row table from checkbox checked
        check: function(text,id,check,path,approvementAction) {

            // Index split Selected Array - Rujukan
            // 0 = id
            // 1 = boolean checked
            // 2 = action for pending
            // 3 = text area
            // 4 = Station ID
            // 5 = Datetime
            // 6 = Parameter

            // console.log(text);
            // console.log(id);
            // console.log(check);
            // console.log(path);
            // console.log(approvementAction);

            // path.path[3].cells[8].innerText == "action"
            // text == "action by remarks"
            console.log(approvementAction);

            if (check === true) {

                // this.selected.push(id + " ~ " + check + " ~ " + path.path[3].cells[8].innerText + " ~ " + text + " ~ " + path.path[3].cells[0].innerText + " ~ " + path.path[3].cells[1].innerText + " ~ " + path.path[3].cells[2].innerText);
                this.selected.push(id + " ~ " + check + " ~ " + approvementAction + " ~ " + text);
            
            }
            else if (check === false){
                for (let i = 0; i < this.selected.length; i++) {
                    if (this.selected[i].split(' ~ ')[0] == id.toString()) {
                        // console.log(this.selected[i]);
                        // this.selected[0] = this.selected[1];
                        this.selected.splice(i,1);
                    }
                }
            }

            this.selectedPendingToHistory = this.selected;

            // console.log(this.selected);

        },

        submitDataPending(){

            // console.log(this.selected);
            this.arrForm = [];

            // console.log(this.tbody_validation_pending[0]["datetime"]);

            for (let i = 0; i < this.tbody_validation_pending.length; i++) {

                this.tbody_validation_pending[i]["datetime"] = this.converttoDBFormat(this.tbody_validation_pending[i]["datetime"]);

                        this.tbody_validation_pending[i]["requestedDt"] = this.converttoDBFormat(this.tbody_validation_pending[i]["requestedDt"]); 

                for (let x = 0; x < this.selected.length; x++) {
                    if (this.tbody_validation_pending[i].id == this.selected[x].split(" ~ ")[0]) {
                        // console.log(this.tbody_validation_pending[i]);
                        this.arrForm.push(this.tbody_validation_pending[i]);
                    }
                }
                
            }

            // console.log(this.arrForm);

            let keys = [
                'id',
                'stationId',
                'datetime',
                'parameter',
                'approverRemarks',
            ],
            result = this.arrForm.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

            // Index split Selected Array - For My Rujukan
            // 0 = id
            // 1 = boolean checked
            // 2 = action for pending
            // 3 = text area
            // 4 = Station ID
            // 5 = Datetime
            // 6 = Parameter

            // console.log(result);

            // this.selectedPendingToHistory = result;

            for (let i = 0; i < result.length; i++) {
                for (let x = 0; x < this.selected.length; x++) {
                    if (result[i].id == this.selected[x].split(" ~ ")[0]) {
                        result[i]["action"] = this.selected[x].split(" ~ ")[2];
                        result[i]["approverRemarks"] = this.selected[x].split(" ~ ")[3];
                        result[i]["datetime"] = result[i]["datetime"].replaceAll('<br>', ' ');
                    }
                }
            }

            // console.log(result);

            let keys2 = [
                'stationId',
                'datetime',
                'parameter',
                'action',
                'approverRemarks',
            ],
            arrayData = result.map(o => Object.assign(...keys2.map(k => ({ [k]: o[k] }))));
            
            
            // console.log(arrayData);

            let submitProceed = "off";
            for (let i = 0; i < arrayData.length; i++) {
                if (arrayData[i].action === "" || arrayData[i].action === null || arrayData[i].approverRemarks === null) {
                    submitProceed = "off";
                }
                else{
                    // submitProceed = null;
                    if (this.selected.length == 0) {
                        submitProceed = "off";
                    }
                    else{
                        submitProceed = null;
                    }
                }
            }

            


            // console.log("nnn",submitProceed);

            console.log(submitProceed);

            if (submitProceed === "off") {
                // console.log("masyuk");
                // console.log("submitProceed:" +  submitProceed);
                this.dialogErrorPending = true;
            }
            else{

                // console.log("submitProceed: is YES null!");
                // console.log("submit pending process");

                // Convert data into json
                let jsonData = JSON.stringify(arrayData);
                // console.log(jsonData);

                // document.getElementById('showSucceedValidatePending').click();
                
                axios.put(this.globalUrl+'maint/editdataval',
                jsonData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.$store.getters.user.token,
                    }
                })
                .then((response) => {
                    // console.log(response.data);
                    // console.log("Success!");
                    this.dialogSucceedPending= true;
                    // document.getElementById('showSucceedValidatePending').click();
                    
                })
                .catch(error => { //eslint-disable-line
                    // console.log(error);
                    // console.log("Unsucces~");
                })

            }
            

            
        },


        checkingAll(){
            // this.check()
            // this.tbody_validation_pending = []

            if (this.click_track == 1){
                this.check();
                this.tbody_validation_pending.forEach(function(res){
                    res["submitCheckbox"] = true;
                });
            }
            else if (this.click_track == 2) {
                this.check();
                this.tbody_validation_pending.forEach(function(res){
                    res["submitCheckbox"] = false;
                });
                this.click_track = 0;
            }

            this.click_track++;

            // console.log(this.tbody_validation_pending);

        },


        alreadySuccessSubmitPending(){
            this.dialogSucceedPending = false;
            document.getElementById('generateReport').click();
        },

        exportExcel(){

            this.itemPerPageReport = -1;

            // Define your style class template.

            let headerName = "<tr = 'border:none !important' ><td colspan ='22' style = 'text-align: center; border:none !important; font-weight: bold'> DATA VALIDATION RECORD FOR STATION "+ this.stationID+" - "+ this.convertUcase(this.locationStation) +" FROM "+ this.convertDateFormat(this.dateFrom) +" TO "+this.convertDateFormat(this.dateTo) +"</td></tr>"
            var style = "<style> th, td { border: 1px solid;}</style>";

            var uri = 'data:application/vnd.ms-excel;base64,'
                , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' + style + '</head><body><table>' + headerName + '{table}</table></body></html>'
                , base64 = function (s) {
                    return window.btoa(unescape(encodeURIComponent(s)))
                }
                , format = function (s, c) {
                    return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
                }

            setTimeout(() => {

                let table = document.querySelectorAll('.v-data-table__wrapper ')
                console.log("hehe", table[0].innerHTML, table, document.querySelectorAll('.v-data-table__wrapper table'))
                let ctx = { worksheet: "QAQC 3RD LEVEL" || 'Worksheet', table: table[0].innerHTML }
                // window.location.href = uri + base64(format(template, ctx))

                let a = document.createElement('a');
                a.href = uri + base64(format(template, ctx))
                a.download = 'QAQC 3RD LEVEL.xls';
                //triggering the function
                a.click();

                this.itemPerPageReport = 10;

            }, 1000)

            },


    },
    mounted(){
        this.load();
        this.converttoDBFormat("01/01/2023 12:30:00 PM");
    }
}
</script>

<style lang="scss">

    @import '~scss/main';

    /* Section Path File/Modul */
    .pathModul{
        padding-bottom: 0px;
    }

    .pathModul > li:nth-child(3) > a,
    .pathModul > li:nth-child(3) > div{
        color: black !important;
    }

    /* Section Filtering Input/Select/Button */
    .container-filtering{
        padding: 0px;
    }

    /* Section Generate Result Datatable Vue */
    // .container-result-generated{
    //     height: calc(100vh - 265px);
    // }

    .subTitle{
        text-align: center !important;
        background: white;
        // color: #1976D2 !important;
        display: none;
        padding: 15px;
    }

    .subTitle_ {
        text-align: center !important;
        background: white;
        // color: #1976D2 !important;
        display: block;
        padding: 15px;
    }

    .tableValidationReport,
    .tableValidationPending,
    .tableValidationHistory{
        margin: 20px 0px;
    }

    .form-datatable{
        padding: 10px 0px 0px 0px;
    }

    // .tableValidationReport > div:nth-child(1) > table > thead > tr > th,
    // .tableValidationPending > div:nth-child(1) > table > thead > tr > th,
    // .tableValidationHistory > div:nth-child(1) > table > thead > tr > th{
    //     padding: 10px 16px;
    // }

    .tableValidationPending > div:nth-child(1) > table > thead > tr > th:nth-child(10){
        white-space: nowrap !important;
    }

    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td:nth-child(1){
      white-space: nowrap !important;
    }

    .tableValidationPending > div:nth-child(1) > table > tbody > tr > td:nth-child(2),
    .tableValidationPending > div:nth-child(1) > table > tbody > tr > td:nth-child(8),
    .tableValidationHistory > div:nth-child(1) > table > tbody > tr > td:nth-child(2){
      white-space: nowrap !important;
    }

    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td{
      padding: 0px !important;
    }

    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td:nth-child(1),
    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td:nth-child(2){
      padding: 0px 16px !important;
    }

    .tableValidationPending > div:nth-child(1) > table > tbody > tr > td{
      padding: 16px;
    }

    .action-chips > div:nth-child(1) > div:nth-child(1) > .v-select__slot > .v-select__selections > span{
        background: white !important;
    }

    .btn-after-table{
        margin: 0px 10px;
    }

    .tableValidationReport > .v-data-table__wrapper, 
    .tableValidationPending > .v-data-table__wrapper, 
    .tableValidationHistory > .v-data-table__wrapper {
        overflow-x: auto !important;
        overflow-y: auto !important;
        // max-height: 408px;
    }

    .v-data-table__empty-wrapper > td{
      text-align: left !important;
    }

    .section-btn-export{
        text-align: right;
    }

    .btn-export-table{
        margin-top: 12px;
    }

    .btn-click-td{
        background: white !important;
        height: inherit !important;
        width: -webkit-fill-available !important;
        padding: 0px !important;
    }

    /* .btn-click-td:hover{
        background: #0081BA !important;
        color: white;
    } */

    .btn-click-td > span{
        background: white;
        height: inherit !important;
        width: -webkit-fill-available !important;
        display: block;
        padding: 11px;
        font-size: 0.95em;
    }

    .btn-click-td > span:hover{
        background: #04c5d6 !important;
        color: white;
    }

    .btn-click-td > .pending{
        background: #02b2c3 !important;
        color: white;
    }

    /* .btn-click-td.selectBtnClick{
        background: black !important;
    } */
    

    /* Special Section For Validation Report */

    /* contain 2nd table */
    #pageLepasSubmitSelectedTD, #container_btnDpn_valReport{
        display: none;
    }

    /* Footer Report */
    .footer-report{
      background: white;
    }
    
    .btn-click-td > span{
        background: white;
    }
   
    .myClass {
        background: #0199be
      }

</style>